<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <div class="page-title">{{ $route.meta.title }}</div>
        <v-btn
          color="primary"
          class="px-6 font-weight-bold"
          depressed
          small
          :to="{ name: 'memberCreate' }"
          :disabled="tableLoading"
          >新增</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'memberDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Member',
  components: {
    Datatable,
  },
  data() {
    return {
      tableLoading: false,
      tableHeaders: [
        { text: '用戶名稱', value: 'name' },
        { text: '用戶類別', value: 'user_type' },
        { text: '電郵地址', value: 'email' },
        { text: '電話', value: 'phone' },
        { text: '狀態', value: 'status' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
    }
  },
  methods: {
    async getUserData() {
      this.tableLoading = true

      try {
        const payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_user', payload)
        this.$func.log('-----Get User List-----')
        this.$func.log(result)

        this.formItemTotal = result.total

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          temp.push({
            id: resultData.id,
            name: resultData.user_name,
            user_type: resultData.user_type,
            email: resultData.email,
            phone: resultData.phone,
            status: resultData.status,
          })
        }

        this.formData = temp
      } catch (error) {
        this.$func.log('-----Get User List fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.formData = []
        this.formItemTotal = 0
      } finally {
        this.tableLoading = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getUserData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getUserData()
    }
  },
}
</script>
